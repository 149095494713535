<template>
  <v-container class="my-10 my-md-16" v-if="user">
    <v-row class="d-flex flex-row pa-3 heading">
      <v-col>
        <h1 class="text-left">{{ $t('navbar.conversions') }}</h1>
        <p class="text-left">
          {{ $t('navbar.conversionsDesc') }}
        </p>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="conversions"
      :options.sync="options"
      :server-items-length="totalConversions"
      :loading="loading"
      class="elevation-0 card rounded-xl pa-3 mb-16"
    >
      <template v-slot:item.platform="{ item }">
        {{ item.platform }}
      </template>
      <template v-slot:item.platform_percent="{ item }">
        {{ formatPercentage(item.platform_percent) }}
      </template>
      <template v-slot:item.payment_creator="{ item }">
        {{ formatCurrency(item.payment_creator) }}
      </template>
      <template v-slot:item.payment_date="{ item }">
        {{ formatDate(item.payment_date) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "@/axios.js";

export default {
  name: "UserConversions",
  data() {
    return {
      user: null,
      conversions: [],
      totalConversions: 0,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['payment_date'],
        sortDesc: [true],
      },
      headers: [
        { text: this.$t('conversions.platform'), value: 'platform' },
        { text: this.$t('conversions.percent'), value: 'platform_percent' },
        { text: this.$t('conversions.payment'), value: 'payment_creator' },
        { text: this.$t('conversions.date'), value: 'payment_date' },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchConversions();
      },
      deep: true,
    },
  },
  methods: {
    checkUser() {
      let user = localStorage.getItem("user");
      if (user) {
        this.user = JSON.parse(user);
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'USD',
      }).format(value);
    },
    formatDate(value) {
      return new Date(value).toLocaleDateString('es-ES');
    },
    formatPercentage(value) {
    return `${(value * 100).toFixed(0)}%`;
    },
    async fetchConversions() {
      this.loading = true;
      try {
        const { page, itemsPerPage, sortBy, sortDesc } = this.options;

        const response = await axios.get('get-user-conversions', {
          params: {
            page: page,
            per_page: itemsPerPage,
            sort_field: sortBy[0],
            sort_order: sortDesc[0] ? 'desc' : 'asc',
          },
        });

        this.conversions = response.data.data;
        this.totalConversions = response.data.total;
      } catch (error) {
        console.error('Error fetching conversions:', error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.checkUser();
    this.fetchConversions();
  },
};
</script>

<style scoped>
.heading h1 {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.heading p {
  font-size: 0.8rem;
  color: rgb(151, 138, 206);
  font-weight: 600;
}

.card {
  border-radius: 10px;
  gap: 20px;
  color: rgb(70, 70, 70);
  box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1) !important;
}
</style>
